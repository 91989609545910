.home-container {
    z-index: 0;
}

.first-container {
    background: linear-gradient(45deg, var(--tertiary-color), var(--primary-color), var(--primary-color-half));
    z-index: 1;
    /* background: linear-gradient(45deg, var(--tertiary-color), #be50ca, var(--primary-color), var(--tertiary-color), var(--primary-color), var(--secondary-color)); */
    /* background-color: var(--background-color); */
}

.stencil {
    color: rgba(255, 255, 255, 0.1);
    transform: rotate(12.5deg);
    font-size: clamp(550px, 85vw, 1550px);
    position: absolute;
    overflow: hidden;
    top: 2rem;
    left: -20rem;
    z-index: 0;
}

@media (max-width: 1200px) {
    .stencil {
        font-size: clamp(650px, 76vw, 800px);
        position: absolute;
        overflow: hidden;
        top: 0.2rem;
        left: -25rem;
        z-index: 0;
    }
}



/* ------------------------------- */
.why-choose-us-background {
    position: relative;
    background: linear-gradient(#fff, rgb(226, 226, 226));
    width: 100%;
}

.custom-shape-divider-bottom-1709119856 {
    position: absolute;
    top: -76px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1709119856 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 78px;
}

.custom-shape-divider-bottom-1709119856 .shape-fill {
    fill: #FFFFFF;
}

/* -------------------------- */

.car-carousel-and-faq-background {
    position: relative;
    background: linear-gradient(#3e3c46, #2c114d);
    width: 100%;
}

.custom-shape-divider-bottom-1709212723 {
    position: absolute;
    top: -99px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1709212723 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100px;
}

.custom-shape-divider-bottom-1709212723 .shape-fill {
    fill: #3E3C46;
}
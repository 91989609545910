.category-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15px, 90px));

    justify-content: center;
    gap: 3vw;
    width: 100%;
    max-width: 1200px;
    margin: 4rem auto 4rem auto;
}

#our-fleet,
#all-category {
    scroll-margin-top: 30px;
    position: relative;

}

.category-box,
.category-box-selected {
    font-size: var(--tiny-font-size);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    align-content: center;
    text-align: center;
    padding: 1rem 0.3rem;
    gap: 10px;
    background: linear-gradient(60deg, #252525b2, #25252586, #2525255e);
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: 0.35s;
    text-transform: capitalize;
    font-weight: 700;
    box-shadow: var(--shadow-1);
    position: relative;
}



.category-box:hover,
.category-box-selected {
    transition: 0.35s;

    background-color: var(--dark-color);
    color: rgb(231, 231, 231);
    box-shadow: var(--shadow-black);
}

.category-box:hover {
    background-color: var(--dark-color);
    color: inherit;
    transform: scale(0.85);
}

.category-icon {
    font-size: var(--h1-font-size);
    aspect-ratio: 1;

}

#icon-bigger {
    font-size: calc(var(--h1-font-size) + 10px);
}

.all-cars-container {
    display: flex;
    gap: 3vw 3vw;
    margin: 2.5rem auto;
    max-width: 1500px;
    justify-items: center;
    justify-content: center;
    flex-wrap: wrap;

}

.scroll-to-cat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #000;
    color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: var(--shadow-black);
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
}

.scroll-to-cat-button:hover {
    transition: 0.2s;
    opacity: 1;
    transform: scale(0.9);
}

.number-of-cars,
.number-of-cars-selected {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    width: 25px;
    aspect-ratio: 1;
    background-color: #252525a1;
    top: -9px;
    right: -9px;
    color: #979797;
    /* border: 2px solid var(--secondary-color); */
    box-shadow: var(--shadow-black);
    opacity: 0.8;
    font-size: var(--tiny-font-size);
    transition: 0.2s;
}

.number-of-cars-selected {
    transition: 0.2s;
    opacity: 1;
    color: #fff;
    background: unset;
    background-color: var(--secondary-color);
    width: 30px;
    font-size: calc(var(--tiny-font-size) + 2px);
    top: -11px;
    right: -11px;
}


@media (max-width: 921px) {
    .all-cars-container {
        display: flex;
        gap: 6vw;
        justify-items: center;
        flex-wrap: wrap;
    }
}

@media (max-width: 647px) {
    .all-cars-container {
        display: flex;
        gap: 30px;
        justify-items: center;
        flex-wrap: wrap;
        margin: 2.5rem 0.5rem;
    }
}

@media (max-width: 1200px) {
    .category-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15px, 90px));
        justify-content: center;
        gap: 3vw;
        width: 100%;
        max-width: 91vw;
        margin: 1rem auto 2rem auto;
    }
}


@media (max-width: 500px) {
    .all-cars-container {
        gap: 24px;
        margin: 0.5rem 0.5rem 3rem 0.5rem;
    }
}

.all-cars-png {
    overflow: hidden;
}

.all-cars-png img {
    filter: drop-shadow(0px 100px 0 white);
    transform: translateY(-100px);
}
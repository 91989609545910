.landing-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 800px;
    margin-top: 25px;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-wrap: balance;
}

.landing-content-h3 {
    color: var(--secondary-color);
    background-color: var(--dark-color);
    box-shadow: var(--shadow-black);
    width: fit-content;
    padding-inline: 20px;
    border-radius: 20px 6px;
    transition: 0.3s;
}

.landing-content h1 {
    transition: 0.3s;
}

.landing-content h1:hover {
    transform: translateX(20px);
    transition: 0.3s;
    text-shadow: var(--purple-text-shadow-thick);
}


.landing-content-h3:hover {
    transform: translateX(20px);
    transition: 0.3s;
}

.landing-content-h3 span {
    color: white;
}

.landing-content p {
    margin-top: -20px;
    font-size: var(--tiny-font-size);
    color: rgb(36, 36, 36);
    text-wrap: balance;
}

.landing-content .button-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    margin: 2rem;
}

.btn,
.btn-big {
    display: inline-block;
    padding: 7px 0.7rem;
    background: var(--dark-color);
    border: 2px solid var(--dark-color);
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    font-size: var(--h4-font-size);
    color: #eaeaea;
    letter-spacing: 1px;
    text-decoration: none;
    font-weight: 900;
    transition: 0.15s;
}

.btn-big {
    font-size: var(--h3-font-size);
    padding: 7px 1.8dvw;
}

.btn {
    font-size: var(--h4-font-size);

}

.btn-small {
    display: inline-block;
    padding: 5px 1.2dvw;
    background-color: var(--dark-color);
    border: 2px solid var(--dark-color);
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    color: #eaeaea;
    letter-spacing: 1px;
    text-decoration: none;
    font-weight: 800;
    font-size: var(--body-font-size);
    transition: 0.15s;
}

.btn:hover,
.btn-small:hover,
.btn-big:hover,
#alternate-button:hover {
    background: var(--secondary-color);
    border-color: white;
    color: #fff;
    transform: scale(1.13);
    transition: 0.15s;
}



#alternate-button {
    background: white;
    border-color: white;
    color: var(--primary-color);
}


.iconImg {
    width: max(30px, 3vw);
    height: max(30px, 3vw);
    margin-right: 0.9rem;

}



.LandingLeft {
    /* grid-area: left; */
    display: flex;
    flex-direction: column;
    padding-left: 2.3vw;
    align-items: center;
    justify-content: center;
    flex: 2;
    /* gap: 50px; */
    /* height: 27vw; */
}

.LandingRightSmall {
    display: none;
}

.button-container {
    display: flex;
    justify-content: center;
}

.LandingRight {
    flex: 1;
    transition: 0.3s;
    margin-right: 100px;
    /* height: 27vw; */
}

.LandingRight:hover {
    transform: translateX(-25px);
    transition: 0.3s;
}

.LandingContainer {
    height: 24vw;
    display: flex;
}

@media (max-width: 1200px) {
    .LandingContainer {
        display: flex;
        flex-direction: column;
        justify-items: center;
        height: 100%;
        padding-top: 20px;

    }

    .LandingRight {
        flex: 0;
        display: none;
    }

    .LandingRightSmall {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
    }

    .LandingRightSmall img {
        height: 100%;
        max-height: 350px;
    }

    .LandingLeft {
        /* grid-area: left; */
        display: flex;
        flex-direction: column;
        padding-left: 0;
        align-items: center;
        justify-content: center;
        flex: 1;
        margin: 0 20px 20px 30px
    }

    .landing-content-h3 {
        padding: 5px 20px;
        border-radius: 20px 6px;
        transition: 0.3s;
    }

    .landing-content .button-container {
        gap: 1rem;
        margin: 1rem 0.5rem 0 0.5rem;
    }
}



.content {
    position: relative;
}

.content h2 {
    color: #fff;
    /* font-size: 8em; */
    position: absolute;

    transform: translate(-50%, -120%);
}

.content h2:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 3px #11A8E4;
}

.content h2:nth-child(2) {
    color: #11A8E4;
    animation: animate 6s ease-in-out infinite;
}

@keyframes animate {

    0%,
    100% {
        clip-path: polygon(0% 45%,
                16% 44%,
                33% 50%,
                54% 60%,
                70% 61%,
                84% 59%,
                100% 52%,
                100% 100%,
                0% 100%);
    }

    50% {
        clip-path: polygon(0% 60%,
                15% 65%,
                34% 66%,
                51% 62%,
                67% 50%,
                84% 45%,
                100% 46%,
                100% 100%,
                0% 100%);
    }
}
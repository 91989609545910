@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    /* background-color: #fcfcfc; */
}

.navContainer {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    z-index: 1;
}

.navbar {
    height: 60px;
    background-color: transparent;
    position: relative;
    /* position: sticky;
    top: 0;
    background-color: var(--background-color); */
    box-shadow: 0px 7px 18px -15px #000000a8;
    z-index: 1;
}

.logo {
    color: white;
    font-weight: 600;
    /* margin-left: -100px; */
    /* margin-top: 15px; */

}

.logoText {
    position: relative;
    /* font-family: "Rowdies", sans-serif; */
    font-family: "Archivo Black", sans-serif;
    font-weight: 600;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    font-size: var(--h2-font-size);
    text-transform: uppercase;
    letter-spacing: 2px;
    color: var(--secondary-color);
    /* background: linear-gradient(-12deg, var(--primary-color), var(--secondary-color), var(--secondary-color));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; */
    text-shadow: var(--shadow-1);
}

.logoText:hover {
    color: var(--primary-color);
    transform: scale(1.1);
    transition: 0.15s;
}

.menu-icon {
    display: none;
}

/* .book-private-banner {
    display: flex;
    justify-content: center;
    padding: 5px 0;
    font-size: var(--h4-font-size);
    background: linear-gradient(var(--primary-color), transparent);
    z-index: -1;
} */

/* .nav-elements {} */

.nav-elements ul {
    /* padding-top: 15px; */
    display: flex;
    justify-content: space-between;
    list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 50px;
}

.nav-elements ul li:hover,
.nav-elements ul a:hover {
    color: var(--secondary-color);
    transform: scale(1.1) translateY(-2px);
    transition: 0.15s;
}

.nav-elements ul li,
.nav-elements ul a {
    font-size: var(--body-font-size);
    font-weight: 500;
    color: #fff;
    text-decoration: none;
}

.nav-elements ul a.active {
    /* color: var(--primary-color); */
    font-weight: 600;
    position: relative;
}

.nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: white;
}

@media (max-width: 900px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 30px;
    }
}

@media (max-width: 820px) {
    .logo {

        color: white;
        font-size: 30px;
        font-weight: 300;

        /* margin-left: -100px; */
        /* margin-top: 15px; */
    }

    /* .logoText::before {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 1px;
        width: 56px;
        height: 3px;
        background-color: white;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    } */

    .menu-icon {
        /* padding-top: 15px; */
        display: block;
        cursor: pointer;
    }

    .nav-elements {
        position: absolute;
        z-index: 2;
        right: 0;
        top: 50px;
        background-color: var(--background-color);
        width: 0px;
        height: calc(100vh - 60px);
        transition: all 0.3s ease-in;
        overflow: hidden;
    }

    .nav-elements ul li,
    .nav-elements ul a {
        font-size: 17px;
    }

    .nav-elements.active {
        width: 270px;
        max-width: 100%;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
    }

    .nav-elements ul li {
        margin-right: unset;
        margin-top: 22px;
    }

    .navbar {
        height: 50px;

    }
}
.features-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    padding: 1rem 3rem 4rem 3rem;

}



.features-heading {
    font-weight: 700;
    /* color: var(--primary-color-light); */
    text-shadow: var(--purple-text-shadow-thick);
    text-wrap: balance;
    text-align: center;
}

.list-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    /* Adjust the minimum and maximum width of each feature */
    gap: 3rem;
    width: 90%;
    max-width: 90%;
    /* margin-bottom: 4rem; */
}

.individual-feature-container,
.individual-feature-container-alt {
    display: flex;
    flex-direction: column;


    padding: 2rem;
    border-radius: 10px;
    gap: 1rem;
    transition: 0.2s;
}

.individual-feature-container {
    background: linear-gradient(60deg, var(--background-color), var(--background-color), var(--background-color-light));
    box-shadow: var(--shadow-1);
}

.individual-feature-container-alt {
    background: linear-gradient(-60deg, var(--secondary-color), var(--primary-color), var(--primary-color), var(--tertiary-color));
    box-shadow: var(--shadow-black);
}

.individual-feature-container:hover,
.individual-feature-container-alt:hover {
    transition: 0.2s;
    transform: scale(1.05);
    /* background-color: #301833; */
}

.individual-feature-top-bar {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
}

.feature-title {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    font-size: var(--h2-font-size);
    font-weight: 700;
    text-align: center;
    color: white;
}

.icon {
    display: flex;
    color: var(--secondary-color);
    justify-content: center;
    align-items: center;
}

.feature-circle {
    aspect-ratio: 1;
    width: clamp(65px, 4vw, 90px);
    border-radius: 100px;
    font-weight: 700;
    font-size: clamp(0.8rem, 1.2vw, 1.2rem);
    text-align: center;
    background-color: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow-1);
}

.feature-circle-alt {
    aspect-ratio: 1;
    width: clamp(65px, 4vw, 90px);
    border-radius: 100px;
    font-weight: 700;
    font-size: clamp(0.8rem, 1.2vw, 1.2rem);
    text-align: center;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow-black);
}

.feature-description {
    font-size: var(--body-font-size);
    width: 100%;
    font-weight: 600;
    text-align: center;
    color: var(--light-gray)
}

@media (max-width: 768px) {
    .list-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 100%;
        /* Adjust for smaller screens */
    }

    .individual-feature-top-bar {
        flex-wrap: wrap;
        justify-content: center;
    }

    .individual-feature-container {
        display: flex;
        flex-direction: column;
        background-color: var(--background-color);
        border-width: 2px;
        border-color: aqua;
        width: 100%;

        box-shadow: var(--shadow-1);
        padding: 2rem 1rem;
        border-radius: 10px;
        gap: 0.8rem;
    }
}




/* ---------------------------------- */
/* https://www.shapedivider.app/ */

.custom-shape-divider-bottom-1709119856 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1709119856 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 78px;
}

.custom-shape-divider-bottom-1709119856 .shape-fill {
    fill: #FFFFFF;
}
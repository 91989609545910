.map-tile-container {
    /* margin: 2rem; */
    margin-bottom: 140px;
    margin-inline: auto;
    width: 85vw;
    height: 40vw;
    max-width: 1500px;
    max-height: 600px;
    border-radius: 0.75rem;
    box-shadow: var(--shadow-black);
}

.car-locations-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 85vw;
    margin-inline: auto;

}

.description {
    font-size: var(--body-font-size);
    color: #000;
    max-width: 1000px;
    margin-left: 3vw;
}

.custom-icon {
    background-color: var(--secondary-color);
    height: 2em;
    width: 2em;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1.2rem;
    font-weight: 800;
    object-fit: cover;
    box-shadow: var(--shadow-black);
    border-style: solid;
    border-width: 2px;
    border-color: white;
    cursor: pointer;
    z-index: 2;
}

.custom-icon:hover {

    border-color: var(--primary-color);
    /* border-width: 3px; */
    box-shadow: var(--shadow-1);
    background-color: #000;
    transition: 0.2s;
    transform: scale(1.6);
    z-index: 100;
}

.cluster-icon {
    background-color: var(--secondary-color);
    height: 2em;
    width: 2em;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1.2rem;
    font-weight: 800;
    box-shadow: 0 0 0px 5px #fff;
}

@media (max-width: 1200px) {
    .custom-icon {

        height: 1.2em;
        width: 1.2em;

    }

    .custom-icon:hover {

        height: 1.5em;
        width: 1.5em;

    }

}

@media (max-width: 750px) {
    .map-tile-container {
        height: 60vw;
        max-height: 600px;
    }
}
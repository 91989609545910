.faq-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0;
    margin-bottom: 20px;
    scroll-margin-top: 1.5rem;
    /* background-color: var(--background-color); */
    /* background: linear-gradient(#3e3c46, #1E1B32) */
}

.text-box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 0.5rem;
    text-wrap: balance;
    text-align: center;
}

.faq-container h1 {
    text-shadow: var(--purple-text-shadow-thick);
}


.qa-container {
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0;
    gap: 1rem;
    width: 90%;
}
.question-text {
    font-weight: 700;
    border-radius: 10px;
    /* text-shadow: var(--purple-text-shadow-blurred) */
}

.question-container {
    background-color: var(--background-color);
    color: white;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px
}



.answer {
    padding: 1rem;
    color: white;
}

.container {
    width: 90%;
    max-width: 1250px;
    background-color: var(--background-color-light);
    font-size: var(--body-font-size);
    box-shadow: var(--shadow-1);
    margin: 0.5rem;
    border-radius: 10px;
    cursor: pointer;
    align-self: center;

}

.icon {
    aspect-ratio: 1;
    min-width: clamp(40px, 3vw, 55px);
    font-size: clamp(40px, 3vw, 55px);
}
.cd-modal-container {
    background-color: rgba(31, 41, 55, 0.2);
    backdrop-filter: blur(20px);
    padding: 2rem;
    position: fixed;
    inset: 0;
    z-index: 50;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.cd-modal-content {
    background: linear-gradient(to bottom right, var(--tertiary-color), var(--dark-color));
    color: #ffffff;
    padding: 2rem;
    border-radius: var(--border-radius);
    height: 90%;
    max-height: 90vh;
    width: 80%;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    cursor: default;
    position: relative;
    display: flex;
    gap: 1.7rem;
    overflow: hidden;

}

.left-button,
.right-button {
    position: fixed;
    z-index: 100;
    align-self: center;
    font-size: clamp(20px, 5vw, 45px);
    padding: 1.5vw;
    height: 100%;
}

.left-button:hover,
.right-button:hover {
    opacity: 0.6;
}

.left-button {
    left: 20px;
}

.right-button {
    right: 20px;
}




.close-button {
    color: var(--secondary-color);
    display: unset;
    font-size: 75px;
    position: fixed;
    margin: 10px;
    top: 0;
    right: 10px;
    z-index: 100;
    cursor: pointer;

}

.white-close-button-back {
    display: unset;
    position: absolute;
    background-color: white;
    width: 32px;
    height: 32px;
    top: 0px;
    right: 10px;
    margin: 33px;
}

.car-details-car-icon {
    color: rgba(255, 255, 255, 0.1);
    transform: rotate(25.5deg);
    font-size: 250px;
    position: absolute;
    top: -3rem;
    left: -4rem;
}

.car-details-headings {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 5px;
}


.scroll-details-heading {
    padding: 10px;
    border-radius: var(--border-radius);
    background-color: var(--tertiary-color);
    font-size: var(--h5-font-size);
    color: var(--light-gray);
    /* text-decoration-line: underline; */
    margin-bottom: 20px;
    text-align: center;
    box-shadow: 2px 2px 10px #25252581,
        -3px -3px 10px #2c2c2c5d;
}

.price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--secondary-color);
    font-weight: 700;
    gap: 10px;
}

.car-details-map-container {
    /* margin: 2rem; */
    margin-top: 20px;
    margin-inline: auto;
    height: 20vw;
    max-height: 350px;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-black);
    opacity: 1;
}

#details-label {
    color: var(--light-gray);
    font-size: var(--h4-font-size);
}


#details-price-text {
    font-size: var(--big-font-size);
    display: flex;
    align-items: center;
    color: var(--secondary-color);
    font-weight: 700;
    margin-right: 5px;
}

.car-details-scroll h3 {
    text-decoration-line: underline;
    text-shadow: var(--shadow-black);
}

.car-details-scroll ul {
    list-style-type: none;
    text-indent: -20px;
    line-height: calc(var(--tiny-font-size) + 13px);
}

.car-details-scroll ul li {
    margin-bottom: 2px;
}

.emoji-li {
    display: flex;
    flex-direction: row;
    gap: 50;
}

.rest-of-string {
    flex: 1;
    text-indent: 0;
}

.emoji-bullet {
    font-size: var(--h5-font-size);
    width: 28px
}

.before-colon {
    font-weight: 800;
    color: #fff;
    font-size: calc(var(--tiny-font-size) + 1px);
    /* font-style: italic; */
}

.card-rating-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.card-rating-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #F5CD00;
    font-size: var(--h5-font-size);
}

#card-rating-number-text {
    color: var(--light-gray);
    font-size: 12px;
}

.car-details-scroll {
    text-align: left;
    overflow-y: auto;
    color: rgb(218, 206, 221);
    background-color: rgba(0, 0, 0, 0.205);
    border-radius: var(--border-radius);
    font-size: var(--tiny-font-size);
    padding: 10px 15px;
}

.divider-line {
    display: flex;
    align-self: center;
    width: 95%;
    height: 2px;
    background-color: var(--secondary-color)
}

.car-details-image {
    object-fit: contain;
    width: 40vw;
    max-width: 700px;
    max-height: 400px;
    height: auto;
    border-radius: var(--border-radius);
    background-color: rgba(0, 0, 0, 0.26);
    box-shadow: var(--shadow-1);
}

.car-details-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 2;
    gap: 0.5rem;
    max-width: 800px;
}

.car-details-left-small {
    display: none;
}

.car-details-left {
    flex: 1;
    max-width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5vh;
    border-radius: var(--border-radius);
    /* height: 27vw; */
}

.info-box-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 220px));
    gap: 20px 15px;
    justify-content: center;
    justify-content: space-around;
    align-items: center;
    margin: 15px 0 30px 0;
}

@media (max-width: 600px) {
    .info-box-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px 20px;
        justify-content: space-between;

        margin: 15px 0 30px 0;
    }

    .info-box {
        width: 100%;
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
}


.info-box {
    display: flex;
    gap: 10px;

    align-items: center;
    justify-content: space-between;
}

.info-box-title {
    font-weight: 800;
    font-size: 18px;
}

.info-box-value {
    font-size: 16px;
    text-transform: capitalize;
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    align-items: center;
}

.color-circle {
    width: 27px;
    height: 27px;
    border-radius: 100px;
    background-color: black;
    border: solid 2px white;
}

#tick-values {
    font-size: var(--h3-font-size);
}


@media (max-width: 1200px) {
    .cd-modal-container {
        place-items: start;
        justify-items: center;
    }

    .cd-modal-content {
        display: flex;
        flex-direction: column;
        justify-items: center;
        height: 91%;
        max-height: unset;
        width: 94%;
        padding: 1rem;
        overflow: auto;
        gap: 0.6rem;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

    }

    .cd-modal-content::-webkit-scrollbar {
        display: none;
    }


    .car-details-map-container {
        height: 200px;
    }

    .left-button,
    .right-button {
        position: fixed;
        z-index: 100;
        align-self: center;
        font-size: clamp(30px, 4vh, 40px);
        padding: 10px;
        height: auto;
        bottom: 0
    }

    .left-button:hover,
    .right-button:hover {
        opacity: 0.6;
    }

    .left-button {
        left: 5px;
    }

    .right-button {
        right: 5px;
    }

    .close-button {
        color: var(--secondary-color);
        display: unset;
        font-size: 55px;
        position: fixed;
        margin: 2px;
        top: -2px;
        /* Adjust the distance from the bottom as needed */
        right: -2px;
        /* Adjust the distance from the right as needed */
        z-index: 100;
        /* Ensure the close button appears above other content */
        cursor: pointer;
        /* Add cursor pointer for better usability */

    }

    .white-close-button-back {
        display: unset;
        position: absolute;
        background-color: white;
        width: 27px;
        height: 27px;
        top: 0px;
        right: 0px;
        margin: 12px;
    }

    .car-details-headings h1 {
        font-size: var(--h2-font-size);
    }

    .car-details-headings h4 {
        font-size: var(--h5-font-size);
    }

    .emoji-bullet {
        font-size: var(--h4-font-size);
        width: 25px
    }

    .car-details-scroll {
        overflow-y: hidden;
    }

    .cd-modal-container {
        padding: 1rem;
    }

    .car-details-left {
        flex: 0;
        display: none;
    }

    .car-details-left-small {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-top: 10px;
        gap: 0.8rem;
        justify-content: center;
        align-items: center;
    }

    .car-details-image {
        object-fit: contain;
        max-width: 85vw;
        max-height: 400px;
        width: 100%;
        max-height: 350px;
        border-radius: var(--border-radius);

    }

    .car-details-right {
        display: flex;
        align-self: center;
        justify-content: center;
        flex: 1;
        /* margin-bottom: 30px; */
    }
}
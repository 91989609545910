.choose-us-container {

    /* background-color: rgb(253, 253, 253); */
    /* rgb(226, 226, 226); */
    padding-bottom: 5rem;
    color: black;
}

.choose-us-content {
    padding: 2rem;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 800px;
}

.left img {
    /* width: 100vw; */
    /* transform: rotateY(180deg); */
    max-width: clamp(200px, 47vw, 8000px);
    transition: 0.3s;
}

.left img:hover {
    transform: translateY(-20px);
    transition: 0.3s;
}


.right {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* min-width: 600px; */
    max-width: 800px;
}

.explanation-text {
    margin-bottom: 15px
}

.individual-reason-container {
    display: flex;
    align-self: center;
    align-items: center;

    gap: 1.5rem;
}

.reason-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: var(--body-font-size);
    max-width: 725px;
}

.reason-icon {
    display: flex;
    background-color: var(--secondary-color);
    min-width: 65px;
    width: 65px;
    border-radius: 100px;
    height: 65px;
    color: #fff;
    aspect-ratio: 1;
    justify-content: center;
    align-items: center;

}

.reason-title {
    font-weight: 700;
    font-size: var(--h4-font-size);
}

/* .reason-description {
    color: var(--gray);
} */

.reason-text-container {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    text-wrap: stable;
}

@media (max-width: 930px) {
    .choose-us-content {
        padding: 2rem;
        padding-top: 10px;
        display: grid;
        justify-content: center;
        gap: 40px;

    }

    .left img {
        /* width: 100vw; */
        /* transform: rotateY(180deg); */
        max-width: clamp(200px, 65vw, 8000px);
    }
}
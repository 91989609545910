@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Paytone+One&family=Viga&display=swap');
/* font-family: "Viga", sans-serif; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: var(--h1-font-size);
}

h3 {
  font-size: var(--h3-font-size);
}

p {
  font-size: var(--body-font-size);
}

.small-title,
.small-title-black {
  background-color: var(--primary-color);
  width: fit-content;
  padding: 0.3rem 1.2rem;
  border-radius: 15px;
  color: #fff;
  box-shadow: var(--shadow-black);
  font-size: var(--h3-font-size);
  font-weight: 700;
  transition: 0.2s;
}

.small-title-black {
  background-color: var(--dark-color);
  box-shadow: var(--shadow-1);
}

.small-title:hover,
.small-title-black:hover {
  transition: 0.2s;
  transform: scale(1.08);
}


:root {
  --primary-color: #8105d3;
  --primary-color-transparent: rgba(129, 5, 211, 0.178);
  --primary-color-half: #8105d38e;
  --primary-color-light: #e0bbf8;
  --light-gray: #cccccc;
  --gray: rgb(138, 138, 138);
  --secondary-color: #c147f5;
  --tertiary-color: #590080;
  --dark-color: #242323;
  --background-color: #252424;
  --background-color-light: #2d2a2e;
  --background-color-gradient: linear-gradient(145deg, #1e2024, #23272b);
  --shadow-1: 5px 5px 17px var(--primary-color-transparent),
    -3px -3px 12px var(--primary-color-half);
  --shadow-black: 2px 2px 5px #42424281,
    -3px -3px 10px #5555555d;
  --tiny-font-size: clamp(8px, 3vw, 11.5px);
  --body-font-size: clamp(12px, 2vw, 16px);
  --big-font-size: clamp(37px, 8vw, 50px);
  --h1-font-size: clamp(28px, 5vw, 39px);
  --h2-font-size: clamp(25px, 5vw, 33px);
  --h3-font-size: clamp(18px, 4vw, 25px);
  --h4-font-size: clamp(15px, 2.2vw, 21px);
  --h5-font-size: clamp(14px, 2vw, 18px);
  --purple-text-shadow-thick: 3px 3px 2px var(--primary-color);
  --purple-text-shadow-blurred: 3px 3px 12px var(--primary-color);


  --border-radius: 10px
}
.private-only,
.private-available {
    position: absolute;
    top: 10px;
    font-size: var(--body-font-size);
    text-transform: uppercase;
    padding-inline: 5px;
    border-radius: 0.375em;
    box-shadow: var(--shadow-black);
}

.private-only {
    left: 10px;
    background-color: var(--secondary-color);
}

.private-available {
    right: 10px;
    background-color: var(--secondary-color);
}

.private-only span {
    font-weight: 700;
    color: #282c34;
}

.main h2 {
    font-size: var(--h5-font-size);
    margin-bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.main p {
    font-size: var(--body-font-size);
    margin-bottom: 3px;
}

#label {
    color: var(--light-gray);
    font-size: var(--tiny-font-size);
}

#priceText {
    font-size: var(--h3-font-size);
    margin-right: 5px;
}

.card-rating-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px;
    justify-content: space-between;
    width: 100%;

}

.card-rating-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #F5CD00;
    font-size: var(--h5-font-size);
}

#card-rating-number-text {
    color: var(--light-gray);
    font-size: 13px
}

.nft {
    position: relative;
    user-select: none;
    max-width: 250px;
    border: 1px solid #ffffff22;
    background-color: #282c34;
    background: linear-gradient(0deg, rgb(41, 40, 52) 0%, rgba(3, 0, 44, 0.5) 100%);
    box-shadow: 0 6px 10px 5px #00000088;
    border-radius: .7rem;
    overflow: hidden;
    transition: .5s all;
    cursor: pointer;
}

.nft hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid #88888855;
    margin-top: 0;
    font-size: var(--h5-font-size);
}

.nft ins {
    text-decoration: none;
}

.nft .main {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 1rem !important;
}

.nft .main h5 {
    color: rgb(170, 170, 170);
}

.nft .main .description {
    margin: .5rem 0;
    color: #a89ec9;
}

.tokenInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tokenInfo .price {
    display: flex;
    align-items: center;
    color: var(--secondary-color);
    font-weight: 700;
    gap: 5px;
}

.nft .main .tokenInfo .duration ins {
    margin-left: -.3rem;
    margin-right: .5rem;
}

.nft .main .tokenInfo .duration {
    display: flex;
    align-items: center;
    color: #a89ec9;
    margin-right: .2rem;
}

.nft .main .tokenInfo .duration ins {
    margin: .5rem;
    margin-bottom: .4rem;
}

.creator {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: -.8rem;
}

.creator ins {
    color: #a89ec9;
    text-decoration: none;
}

.nft .main .wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ffffff22;
    padding: .3rem;
    margin: 0;
    margin-right: .5rem;
    border-radius: 100%;
    box-shadow: inset 0 0 0 4px #000000aa;
}

.nft .main .wrapper img {
    border-radius: 100%;
    border: 1px solid #ffffff22;
    aspect-ratio: 1;
    width: 2rem;
    height: 2rem;
    object-fit: cover;
    margin: 0;
}

.nft::before {
    position: fixed;
    content: "";
    box-shadow: 0 0 100px 40px #ffffff5b;
    top: -10%;
    left: -400%;
    transform: rotate(-45deg);
    height: 60rem;
    transition: .7s all;
}

.nft:hover {
    border: 1px solid #ffffff44;
    box-shadow: 0 5px 20px 10px #000000aa;
    transform: scale(1.015);
}

.nft:hover::before {
    filter: brightness(.5);
    top: -100%;
    left: 200%;
}

.tokenImage {
    border-radius: .5rem;
    width: 100%;
    aspect-ratio: 1/1;
    height: auto;
    max-height: 230px;
    object-fit: cover;
    margin-bottom: 12px;
}

.bg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bg h1 {
    font-size: 20rem;
    filter: opacity(0.5);
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 4px;
    padding: 6px 0;
    margin-bottom: 7px;
}

.grid-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 3px;
    border-radius: 5px;
    font-size: var(--tiny-font-size);
    line-height: var(--tiny-font-size);
    word-wrap: break-word;
    text-align: center;
    text-transform: capitalize;
}

@media (max-width: 647px) {
    .nft {
        max-width: 200px;
    }

    .nft .main {

        padding: 0.7rem !important;
    }

    .private-only,
    .private-available {
        font-size: var(--tiny-font-size);
    }

    .grid-item {
        font-size: calc(var(--tiny-font-size));
        line-height: calc(var(--tiny-font-size));

    }

    .card-rating-box {
        gap: 3px;
        font-weight: 600;
    }

    .main p {
        font-size: calc(var(--body-font-size) - 1px);
    }

    #card-rating-number-text {
        color: var(--light-gray);
        font-size: calc(var(--h5-font-size) - 1px);
    }

    .nft .main h5 {
        color: rgb(170, 170, 170);
        font-size: var(--body-font-size);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 3px;
    }

    .main h2 {
        font-size: var(--body-font-size);
        margin-bottom: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    #label {
        font-size: calc(var(--tiny-font-size));
    }

    #priceText {
        font-size: calc(var(--h3-font-size));
        margin-right: 4px;
    }
}


.small-card-card-container {
    display: flex;
    gap: 10px;
    position: relative;
    user-select: none;
    width: 85vw;
    max-width: 85vw;
    border: 1px solid #ffffff22;
    background-color: #282c34;
    background: linear-gradient(0deg, rgb(41, 40, 52) 0%, rgba(3, 0, 44, 0.5) 100%);
    box-shadow: 0 6px 10px 5px #00000088;
    border-radius: .7rem;
    overflow: hidden;
    transition: .5s all;
    cursor: pointer;
    padding: 0.8rem 0.6rem;
}

.small-card-main {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
    padding-bottom: 6px;
}

.small-card-main h5 {
    text-align: center;
}

.smallImage {
    border-radius: .5rem;
    height: 100%;
    aspect-ratio: 1/1;
    width: auto;
    max-height: 140px;
    object-fit: cover;
}

.small-car-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#small-private {
    left: 18px;
    top: 7px;
    right: unset;
}

@media (max-width: 500px) {

    .grid-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        align-self: center;
        overflow: hidden;
        padding: 0px 0;
    }

    .grid-item {
        display: flex;
        flex-direction: column;

    }

}
.image-gallery-thumbnail-image {
    object-fit: cover;
    border-radius: 0.375rem;
}

.image-gallery-thumbnail {
    cursor: pointer;
    border-radius: 0.375rem;
}

.actual-image {
    border-radius: 0.375rem;
    max-height: 300px;
    max-width: 40vw;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.26);

}

.actual-image img {
    border-radius: 0.375rem;
    object-fit: cover;
    height: 300px;
}

.actual-image-fullscreen {
    object-fit: contain;
    width: 100%;
}

.image-gallery-thumbnails-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 210px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 5px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    /* Allow thumbnails to wrap to the next line */
}

.image-gallery-thumbnails-container::-webkit-scrollbar {
    display: none;
}

.thumbnail-image {
    flex: 1;
    min-width: 100px;
    max-width: 140px;
}

@media (max-width: 1200px) {
    .actual-image {

        max-width: 83vw;
        height: 41vw;
        max-height: 450px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.26);
    }

    .actual-image img {
        object-fit: contain;
        height: 41vw;
    }


}
@import url('https://fonts.googleapis.com/css?family=Francois+One');

/* Scroll Bar */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  background-image: -webkit-linear-gradient(45deg,
      rgba(255, 255, 255, .2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, .2) 50%,
      rgba(255, 255, 255, .2) 75%,
      transparent 75%,
      transparent)
}


.boldText {
  color: rgb(31, 31, 31);
  font-weight: 700;
}

.App {
  text-align: center;

}

.headingText {
  /* font-family: 'Francois One'; */
  /* text-decoration-line: underline; */
  /* font-size: 4.5vw; */
  font-size: max(2.5rem, 4.5vw);
  color: #ff9a17;
  font-weight: 700;
  padding-top: 1.5rem;
  padding-bottom: 1.2rem;
  line-height: 1.05;
  /* text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6); */
}

.separator {
  height: 1px;
  background-color: rgb(97, 97, 97);
  width: 85%;
  border-radius: 50px;
  margin: auto;
  margin-bottom: 25px;
  margin-top: 25px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.FullScreen {
  min-height: 100vh;
  color: white;
  background-color: var(--background-color);
}

.App-header {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button {
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 2px solid transparent;
  padding: .5rem 1.3rem;
  font-size: 1.5rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  cursor: pointer;
  margin-bottom: 10px;
}